import { Navigate, Outlet } from "react-router";
import {
  getCurrentAccessToken,
} from "./authSlice";
import { useSelector } from "react-redux";

export default function PublicRoute() {

  const token = useSelector(getCurrentAccessToken);
  return <>{token ? <Navigate to="my-loans" replace/> : <Outlet />}</>;
}
