import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_BFF_WEB_URL } from "utilities/Constant";

export interface PreferencesResponse {
  preferenceCenterLink: string;
}


export const complianceApi = createApi({
  reducerPath: "complianceApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_BFF_WEB_URL }),
  endpoints: (build) => ({
    getPreferencesUrl: build.query<PreferencesResponse, string>({
      query: (prospectId) => `/compliance/${prospectId}/preference-center-url`,
    }),
  }),
});

export const { useGetPreferencesUrlQuery } = complianceApi;
