import { Accept } from "react-dropzone";

//header
export const SUPPORT_NUMBER = "Support #: 877-214-4431";

//validation
export const REGEX_EMAIL = /^[\w-.]+@([\w-]+\.)+[\w-]{2,7}$/;
export const REGEX_PHONE_PERMITTED_CHARACTERS = /[)( -]+/;
export const REGEX_PHONE_PROHIBITED_CHARACTERS = /[^\d)( -]/;
export const REGEX_PHONE_PERMITTED_LENGTH = /^\d?((?:\d){10})$/;

// constants.tsx
export const ApplicationName = "FrontEnd";
export const NH_VERSION = process.env.REACT_APP_NH_FE_VERSION ?? "0.0.0.0";
export const NH_INFO_VERSION = process.env.REACT_APP_NH_FE_INFO_VERSION ?? "0.0.0.0";
//consent links
export const PARTNERSLINK = "https://www.newamericanfunding.com/legal/partners/";
export const TERMSOFUSELINK = "https://www.newamericanfunding.com/legal/terms-of-use-website/";
export const AGREEMENTLINK = "https://www.newamericanfunding.com/legal/electronic-consent-agreement/";
export const PRIVACYLINK = "https://www.newamericanfunding.com/legal/privacy-policy/";
export const NMLSLINK = "https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/6606";
export const STATELICENSELINK = "https://www.newamericanfunding.com/legal/state-licensing/";
export const RAPID_APP_REDIRECTION_LINK = "https://www.newamericanfunding.com/lfj-lps/raredirect/";

//input type
export const FIRST_NAME = "Please enter your first name.";
export const LAST_NAME = "Please enter your last name";
export const EMAIL = "Please enter your email";
export const MOBILE_NUMBER = "Please enter your phone number";
export const LOAN_PURPOSE = "Please select a loan purpose";

//input errors
export const INVALID_EMAIL_ERROR = "Please enter a valid email address.";
export const INVALID_LOGIN_ERROR = "Password is incorrect, please try again";
export const INVALID_PASSWORD_ERROR = "Password is invalid";
export const CONFIRM_PASSWORD = " Please confirm your password.";

//input required
export const REQUIRED_EMAIL = "Please enter your email";
export const REQUIRED_PASSWORD = "Please enter your password";
export const REQUIRED_PASSWORD_CONFIRMATION = "Please confirm your password";

//Phone number erros
export const REQUIRED_PHONE_ERROR = "Please enter your phone number";
export const REQUIRED_SPOUSE_PHONE_ERROR = "Please enter your spouse's phone number";
export const REQUIRED_COBORROWER_PHONE_ERROR = "Please enter your co-borrower's phone number";
export const INVALID_PHONE_ERROR = "Please enter a valid phone number";

//password checker phrases
export const PWD_CHECK_MIN_LENGTH = "Be a mininum of 8 characters";
export const PWD_CHECK_UPPER_CASE = "Include at least one uppercase letter (A-Z)";
export const PWD_CHECK_LOWER_CASE = "Include at least one lowercase letter (a-z)";
export const PWD_CHECK_NUMBER = "Include at least one number (0-9)";
export const PWD_CHECK_SPECIAL_CHAR = 'Include at least one special character ("#$%,etc.)';
export const PWD_MATCH_ERROR = " Passwords don't match. Please try again. ";

//password min characters
export const PWD_MIN_LENGTH = 8;

export const BASE_BFF_WEB_URL = window.env.REACT_APP_BASE_BFF_WEB_URL;
export const DEFAULT_HTTP_TIMEOUT = 1000; //units in milliseconds
export const APPINSIGHTS_CONNECTION_STRING = window.env.APPINSIGHTS_CONNECTION_STRING;

export const DROPDOWN_YEARS_FOR_CALENDAR = 40;

export const MAX_RACE_SELECTIONS = 5;

export const DISPLAY_STEP_CODE = window.env.DISPLAY_STEP_CODE;

export const CONTACT_US = "855-744-6990";

export const REQUIRED_CODE = "Invalid Code";

// error message contact details
export const Support = "(877) 214-4431";
export const PhoneNumber = "877-214-4431";

// errors
export const RegistrationError = ["There was an error with your registration.", `Please contact Technical Support at ${Support}`];

export const CreatePasswordFieldEmptyError = "You must create a password";

//MFA errors
export const LoginError = ["There was an error with your login.", `Please contact Technical Support at ${Support}`];

export const LoginErrorOne = "There was an error with your login.";
export const LoginErrorTwo = "Please contact Technical Support at ";
export const OTPExpiredError = "Code is expired. Please click Resend Code and try again.";
export const InvalidOTPError = "Invalid code";
export const ResetPasswordError = "Please use a password you haven't used before.";
export const ResetPasswordCodeError = "Invalid Password Reset Code";

//footer
export const FOOTER_CONTACT_US = "(855) 744-6990";
export const ForgotPasswordError = [`There was an error with your password reset.Please contact Technical Support at ${Support}`];

//Mortgage button
export const MortgageButton = "https://www.newamericanfunding.com/myloan/";

//Login error
export const MultipleLoginFailErrorOne = "Maximum number of attempts reached.";
export const MultipleLoginFailErrorTwo = "Please try again in 10 minutes";
export const MultipleLoginFailErrorThree = "or click on the Forgot Password link.";

//Token
export const ACCESS_TOKEN_EXPIRATION_MINUTES = 10;
export const SKEW_TIME_IN_MINUTES = 0.5;

//Dashboard
export const DASHBOARD_LOAN_PROGRESS_STATUS_UNKNOWN_WARN =
  "We are unable to provide current status at this time. Please check back later or contact your loan officer.";

export const DASHBOARD_LOAN_INFO_WARNING =
  "We are having technical issues displaying your most recent status.  Please check back later or contact your loan officer";

export const DASHBOARD_MAXFILES_LIMIT_REACHED_ERROR =
  "You’ve reached the max limit of 12 documents. To upload more, please delete some files or return to the dashboard after completing this round of uploads.";

export const DOCUMENT_MAX_SIZE_IN_BYTES = 20 * 1024 * 1024;

//Legal Information
export const Legal_Information_One = 'By clicking "Continue", I acknowledge that I have reviewed the ';
export const Legal_Information_Two =
  " and agree by electronic signature to: 1) I am providing written instructions to New American Funding under the Fair Credit Reporting Act authorizing New American Funding to obtain information from my personal credit profile or other information from a consumer reporting agency. I authorize New American Funding to obtain such information solely to conduct a pre-qualification for credit. 2) Receive disclosures and communications about my loan inquiry in electronic form. (I confirm I have access to a working computer and/or digital service where I can view and manage my information.) 3) I authorize New American Funding and its network of ";
export const Legal_Information_Three =
  " to contact me regarding a mortgage loan, real estate and/or home-related product or services that my be of interest to me at the telephone number(s) listed above using an automated dialing system, pre-recorded or artificial voice, and/or SMS messaging even if the telephone number I provided is a cellular phone or other service for which I may be charged. I understand that I am not required to provide this consent in order to obtain goods and services from NAF. I agree that NAF and its ";
export const Legal_Information_Four =
  " may contact me by mail, telephone or email in connection with my request even if my telephone number or email address appears on NAF internal Do Not Call/Do Not Email List, a State or National Do Not Call Registry, or any other Do Not Contact List/Do Not Email list. 4) to the terms of New American Funding's ";

//Legal Compliance Verbiage
export const Legal_Compliance_One = 'By clicking "Continue", I acknowledge that I have reviewed the ';
export const Legal_Compliance_Two =
  " and agree by ESIGN signature to the following: 1) I am providing written instructions under the Fair Credit Reporting Act authorizing New American Funding to obtain information from my personal credit profile or other information from a consumer reporting agency solely to conduct a prequalification for credit. 2) Receive disclosures and communications about my loan inquiry and any loan that I obtain from New American Funding in electronic form. (I confirm I have access to a working computer and/or digital device where I can view and manage my information.)  ";
export const Legal_Compliance_Three = `3) That I consent to New American Funding and parties calling on its behalf to contact me at the number that I provided above for marketing purposes, including through the use of automated technology and SMS/MMS messages. I acknowledge my consent is not required to obtain any good or service, and that I can be connected with New American Funding without providing consent by calling ${PhoneNumber}. I consent to be contacted even if my phone number or email address appears on a New American Funding Do Not Contact/Do Not Email list, a State or National Do Not Call Registry or any other Do Not Contact list. `;
export const Legal_Compliance_Four = "4) To the terms of New American Funding’s  ";

//Assistant Note
export const Assistant_Note = (
  <p>
    If you are using a screen reader or other auxiliary aid and are having problems using this website please call <strong>800-450-2010</strong> Ext.
    7100 for assistance.
  </p>
);

//Dashboard headers
export const MyDashboard = "My Dashboard";
export const MyDocuments = "My Documents";

//LoanStatus
export const Funded = "Funded";

//ApplicationRoles
export const ApplicationRoleTypes = {
  PrimaryBorrower: "PrimaryBorrower",
  NonSpousalCoBorrower: "NonSpousalCoBorrower",
};

export const LoanStatusNames = {
  Unknown: "Unknown",
  Started: "Started",
};

export const ApplicationStatusNames = {
  Completed: "Completed",
};

export const LoanPurposeNames = {
  Purchase: "Purchase",
  Refinance: "Refinance",
};

//Registration Page Greeting texts

export const puchasePathGreeting = "Next Step to Your New Home!";
export const refinancePathGreeting = "Next Step to Your Financial Goals!";
export const unknownPurposeGreeting = "Discover your lending options.";
export const createPasswordGreetingDesktopView = [
  "A loan specialist will call you soon to",
  "discuss your options. Meanwhile, create a profile",
  "with a password to continue the application.",
];
export const createPasswordGreetingPurchaseMobileView = [
  "A loan specialist will call you soon to",
  "discuss your options. Meanwhile,",
  "create a profile with a password to",
  "continue the application.",
];
export const createPasswordGreetingRefinanceMobileView = [
  "A loan specialist will call you soon to",
  "discuss your options. Meanwhile, create a",
  "profile with a password to continue",
  "the application.",
];
export const createPasswordGreeting = [
  "Simply create a profile with a password",
  "to continue the application."
];
export const defaultCoBorrowerGreeting = [
  "To get started, please create a password so that you can come back to this information, at your convenience."
]
export const palGreetingText = [
  "Create a password below to upload, view and manage required",
  "mortgage documents, and track your loan progress!"
];
export const palGreetingTextMobile = [
  "Create a password below to upload, view",
  "and manage required mortgage",
  "documents, and track your loan progress!"
];
export const DigitalLeadGreeting = "Set up your account to stay on track and monitor your progress every step of the way.";

// Welcome Page Subtext
export const welcomeSubtext =
  "You may see some of your information pre-filled or pre-selected. Review and edit if needed. At the end, you can securely upload your documents in your personalized dashboard if you choose.";

// External Registration Page

export const registerExternalUserDesktopViewContent =
  "Create a password below to upload, view and manage required mortgage documents and track your loan progress!";
export const registerExternalUserMobileViewContent = (
  <>
    Create a password below to upload, view
    <br />
    and manage required mortgage
    <br /> documents and track your loan progress!
  </>
);

export const NoLoanInfoText =
  "We are unable to provide property and loan details at this time. Please check back later or contact your loan officer.";

export const NafInsuranceAd_Purchase =
  "For your home loan, you’ll need to provide proof of insurance. NAF Insurance has you covered, shopping 40+ top carriers in seconds to save you time and money. On average, NAF Insurance customers are saving $638 annually and closing their loan 2 days faster!";
export const NafInsuranceAd_Refinance =
  "If you’re refinancing your home, why not also “refinance” your home insurance? NAF Insurance is saving customers $638 annually, on average, compared to their previous policy, shopping 40+ top carriers in seconds.";
export const NafInsuranceAd_Disclaimer_Title = "Disclaimer";
export const NafInsuranceAd_Disclaimer_Content =
  "*New American Funding is not a licensed insurance agency or broker and does not provide insurance quotes to consumers in compliance with applicable state insurance licensing statutes. While you are required to get home insurance before closing, you are not required to get insurance through NAF Insurance - or any particular broker.";
export const NafInsuranceAd_Loader_Message1 = "Bundle Home and Auto coverage to unlock additional Savings";
export const NafInsuranceAd_Loader_Message2 = "Please do not refresh or click Back in your browser";
export const NafInsurance_Loader_Header_desktop = "We’re transferring you to NAF Insurance powered by Matic";
export const NafInsurance_Loader_Header_Mobile_Part1 = "We’re transferring you to";
export const NafInsurance_Loader_Header_Mobile_Part2 = "NAF Insurance powered by Matic";

export const DefaultAllowedExtension = "pdf";
export const DefaultAcceptedFileType: Accept = {
  "application/pdf": [`.${DefaultAllowedExtension}`],
};
export const AvailableFileTypes: Accept = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "image/emf": [".emf"],
  "text/html": [".htm", ".html"],
  "image/jpeg": [".jpg", ".jpeg", ".jpe"],
  "application/pdf": [".pdf"],
  "image/png": [".png"],
  "image/tiff": [".tif", ".tiff"],
  "text/plain": [".txt"],
  "application/vnd.ms-xpsdocument": [".xps"],
};

export const PalDocumentDefaultName = "PreApprovalLetter.pdf";

//condition based constant
export const AdditionalDocumentationCondition = "Additional Documentation - Upload documents that have been requested";
export const DefaultConditionsCount = 1;

//source code
export const RAPID_APP_SOURCE_CODE = 12345678;

//StepCodes
export const VOA_TOS_StepCode = "H17";
