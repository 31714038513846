import { Button } from "components/ui/button/Button";
import Metadata from "components/metadata/Metadata";
import "./single-choice-with-data.scss";
import {
  NextStepActionResponse,
  useSetApplicationStepAnswerMutation,
} from "pages/application/applicationService";
import { getStepData } from "components/question/questionSlice";
import { useSelector } from "react-redux";
import { getCurrentUserApplicationId } from "features/authSlice";
import { SkipButton } from "pages/application/question/Question";


const Options = ({
  stepActions,
}: {
  stepActions: NextStepActionResponse[];
}) => {
  const [updateApplication] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const applicationId = useSelector(getCurrentUserApplicationId);
  return (
    <div className="options">
      {stepActions.map(({ stepActionId, label, hasPreviouslySelected, actionType }, index) => {
        const handleSubmit = async () => {
          await updateApplication({
            id: applicationId,
            stepActionId,
          });
        };
        return (
          <Button
            key={index}
            title={label}
            onClick={() => void handleSubmit()}
            variant= {actionType == "loop"? "outline-primary":"primary"}
            className={`options__button ${
              hasPreviouslySelected ? "active" : ""
            }`}
          />
        );
      })}
    </div>
  );
};

const SingleChoiceWithData = () => {
  const stepData = useSelector(getStepData);

  const { nextStepActions: stepActions, texts: metadata, skipAction } = stepData;

  return (
    <div className="single-choice-with-data">
      {metadata.length > 0 && <Metadata metadata={metadata} />}
      <Options stepActions={stepActions} />
      {skipAction && <SkipButton skipAction={skipAction} />}
    </div>
  );
};

export default SingleChoiceWithData;
