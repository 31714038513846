import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import applicationReducer from "pages/application/applicationSlice";
import { applicationApi } from "pages/application/applicationService";
import { assetVerificationApi } from "pages/application/assetVerificationService";
import { welcomePathApi } from "pages/welcome/welcomePathService";
import questionReducer from "components/question/questionSlice";
import { dashboardApi } from "pages/dashboard/home/dashboardService";
import { registrationApi } from "pages/registration/registrationService";
import authReducer from "features/authSlice";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { versionApi } from "pages/version/versionService";
import { loginApi } from "pages/login/loginService";
import loginReducer from "pages/login/loginSlice";
import { mfaApi } from "pages/MFA/mfaService";
import { authenticationApi } from "pages/authentication/authenticationService";
import { forgotPasswordApi } from "pages/forgot-password/forgotPasswordService";
import { recoverApi } from "pages/recover-password/recoverPasswordService";
import { featureFlagApi } from "services/common/featureFlagService";
import { complianceApi } from "services/common/complianceService";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["auth", "login"],
};
const reducer = combineReducers({
  auth: authReducer,
  application: applicationReducer,
  question: questionReducer,
  login: loginReducer,
  [versionApi.reducerPath]: versionApi.reducer,
  [featureFlagApi.reducerPath]: featureFlagApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [applicationApi.reducerPath]: applicationApi.reducer,
  [assetVerificationApi.reducerPath]: assetVerificationApi.reducer,
  [welcomePathApi.reducerPath]: welcomePathApi.reducer,
  [registrationApi.reducerPath]: registrationApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [forgotPasswordApi.reducerPath]: forgotPasswordApi.reducer,
  [mfaApi.reducerPath]: mfaApi.reducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  [recoverApi.reducerPath]: recoverApi.reducer,
  [complianceApi.reducerPath]: complianceApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);
export const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      applicationApi.middleware,
      dashboardApi.middleware,
      assetVerificationApi.middleware,
      welcomePathApi.middleware,
      registrationApi.middleware,
      loginApi.middleware,
      versionApi.middleware,
      mfaApi.middleware,
      authenticationApi.middleware,
      forgotPasswordApi.middleware,
      recoverApi.middleware,
      featureFlagApi.middleware,
      complianceApi.middleware,
    ]),
});
setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
