import { Button } from "components/ui/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./loan-card.scss";
import classNames from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ApplicationLoanStatus } from "pages/application/applicationService";

interface LoanDetailProps {
  label: string;
  value: string;
  showTooltip?: boolean;
}

const LoanDetail = ({ label, value, showTooltip }: LoanDetailProps) => (
  <div className="loanCard-wrapper__detail">
    <span className="loanCard-wrapper__keyName">{label}:</span>
    <span className="loanCard-wrapper__value" title={showTooltip ? value : undefined}>
      {value}
    </span>
  </div>
);

const getStatusIcon = (status: ApplicationLoanStatus) => {
  const icons: Record<ApplicationLoanStatus, IconProp> = {
    new: solid("flag-checkered"),
    incomplete: solid("hexagon-exclamation"),
    started: solid("paper-plane"),
    processing: solid("paper-plane"),
    submittedToUnderwriting: solid("paper-plane"),
    underReview: solid("paper-plane"),
    conditionallyApproved: solid("paper-plane"),
    finalReview: solid("paper-plane"),
    clearedToClosed: solid("paper-plane"),
    funded: solid("piggy-bank"),
  };

  return <FontAwesomeIcon icon={icons[status]} />;
};
const formatStatus = (status: string) => {
  const spacedStatus = status.replace(/([a-z])([A-Z])/g, "$1  $2");
  const formatted = spacedStatus.charAt(0).toUpperCase() + spacedStatus.slice(1);
  return formatted.replace(/\bTo\b/g, "to");
};

interface LoanCardProps {
  property: string;
  loanPurpose: string;
  loanAmount: string;
  status: ApplicationLoanStatus;
  startedDate: string;
  documentStatus?: boolean;
  index: number;
  handleLoanCardClick: (index: number) => void;
}

export default function LoanCard({
  status,
  property,
  loanPurpose,
  loanAmount,
  startedDate,
  documentStatus,
  index,
  handleLoanCardClick
}: LoanCardProps) {
  const statusClass = classNames({
    greeen: status === "new",
    red: status === "incomplete",
    blue: ["started", "processing", "submittedToUnderwriting", "conditionallyApproved", "underReview", "finalReview", "clearedToClosed"].includes(status),
    grey: status === "funded",
  });
  return (
    <div className="loanCard">
      <div className="loanCard-wrapper">
        <div className={`loanCard-wrapper__status ${statusClass}`}>
          <span>{getStatusIcon(status)}</span>
          <span>{formatStatus(status)}</span>
        </div>
        <LoanDetail label="Property" value={property} />
        <LoanDetail label="Loan Purpose" value={loanPurpose} />
        <LoanDetail label="Amount" value={loanAmount} />
        <LoanDetail label="Started" value={startedDate} />
        <div className="loanCard-wrapper__doc-notify">{documentStatus ? "New document available" : ""}</div>
        <div className="loanCard-wrapper__selectBtn">
          <Button title="Select" onClick={() => handleLoanCardClick(index)} />
        </div>
      </div>
    </div>
  );
}
