import { useSelector } from "react-redux";
import { getLeadProvider } from "features/authSlice";
import { LeadProvider } from "pages/registration/registrationService";
import WelcomeVersonOne from "./WelcomeVOne";
import WelcomeMultiPath from "./WelcomeMultiPath";

export default function Welcome() {
  const leadProvider = useSelector(getLeadProvider);
  const isLPMicrosite = () => leadProvider.toLowerCase() == LeadProvider.Microsite.toLowerCase();

  return <>{isLPMicrosite() ? <WelcomeVersonOne /> : <WelcomeMultiPath />}</>;
}
