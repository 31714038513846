import useDesktopChecker from 'hooks/useDesktopChecker';
import '../raNHRedirect.scss';
import nafHubLogoImage from 'assets/images/nafhub-white-background-logo.svg';
import phoneIcon from 'assets/images/phone_icon_white_background.svg';
import { PhoneNumber } from 'utilities/Constant';

const Header = () => {
  const isDesktop = useDesktopChecker();
  return (
    <div className='redirection-message-header'>
      <img src={nafHubLogoImage} alt='naf image' className='nafHubLogo' />
      {isDesktop ? <p>Need help? 877-214-4431</p> : <a href={`tel:${PhoneNumber}`} ><img src={phoneIcon} alt='contact-button' /></a>}
    </div>
  )
}

export default Header;