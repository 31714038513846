import { ReactComponent as CloseIcon } from "assets/images/xmark.svg";
import PreApprovalAnimation from "assets/images/PAL.gif";
import { Button } from "components/ui/button/Button";
import "./pal-popup.scss";

interface PalPopupProps {
  readonly onClose?: () => void;
  readonly handleClick: () => void;
}

export default function PalPopup({ onClose, handleClick }: PalPopupProps) {
  return (
    <div className="pal-popup">
      <button className="pal-popup__close-button" onClick={onClose} aria-label="Close">
        <CloseIcon />
      </button>
      <div className="pal-popup__animation">
        <img src={PreApprovalAnimation} alt="Pre-Approval Animation" />
      </div>
      <h2 className="pal-popup__title">Congratulations!</h2>
      <p className="pal-popup__message">You’ve been pre-approved!</p>
      <div className="pal-popup__action-button">
        <Button title="View pre-approval letter" type="round" variant="primary" onClick={handleClick} />
      </div>
    </div>
  );
}
