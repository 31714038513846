import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDesktopChecker from "hooks/useDesktopChecker";
import { NavLink, useLocation } from "react-router-dom";
import { showDocuments } from "utilities/UserversionFeatures";
import { getCurrentTooltipIndex, setCurrentTooltipIndex } from "features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { tooltipIndexValues } from "pages/dashboard/home/Dashboard";
import GuideToolTip from "../guideToolTip/GuideToolTip";
import "./navBarUtilities.scss";

export interface INavBarProps {
  userName?: string;
  showNavLinks?: boolean;
  tooltip?: boolean;
  showPreferences?: boolean;
}

export function NavLinks({ className = "" }: Readonly<{ className?: string }>) {
  const navbarClass = "navbar__links" + " " + className;
  const isDesktop = useDesktopChecker();
  const currentTooltipIndex = useSelector(getCurrentTooltipIndex);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isDashboardActive = pathname === "/dashboard" && currentTooltipIndex !== tooltipIndexValues.documents;
  const isDocumentsActive = pathname === "/dashboard/documents" || currentTooltipIndex === tooltipIndexValues.documents;

  const highlightClass = currentTooltipIndex === tooltipIndexValues.documents ? "highlight" : "";

  return (
    <>
      {isDesktop ? (
        <div className={navbarClass}>
          <NavLink to="/dashboard" className={`link ${isDashboardActive ? "active" : ""}`} end>
            <p>My Dashboard</p>
          </NavLink>
          {showDocuments() && (
            <NavLink to="/dashboard/documents" className={`link ${isDocumentsActive ? "active" : ""} ${highlightClass}`}>
              My Documents
            </NavLink>
          )}
          {currentTooltipIndex === tooltipIndexValues.documents && (
            <div className="tooltip3">
              <GuideToolTip
                contentHeading="My Documents"
                tooltipNo="3 of 3"
                content="Navigate here to view your documents."
                buttonName="Explore your dashboard"
                buttonOnClick={() => {
                  dispatch(setCurrentTooltipIndex(tooltipIndexValues.completed));
                }}
                cancelClick={() => {
                  dispatch(setCurrentTooltipIndex(tooltipIndexValues.completed));
                }}
                customButtonStyle="tooltip3-button-style"
              />
            </div>
          )}
        </div>
      ) : (
        <div className={navbarClass}>
          <NavLink to="/dashboard" className="link" end>
            <FontAwesomeIcon className="link__icon" icon={regular("table-columns")} />
            <p className="link__text">My Dashboard</p>
          </NavLink>
          {showDocuments() && (
            <NavLink to="/dashboard/documents" className="link">
              <FontAwesomeIcon className="link__icon" icon={regular("file-lines")} />
              <p className="link__text">My Documents</p>
            </NavLink>
          )}
          <div className="lines"></div>
        </div>
      )}
      {currentTooltipIndex !== tooltipIndexValues.initial && currentTooltipIndex !== tooltipIndexValues.completed && (
        <div className="transparent-overlay"></div>
      )}
    </>
  );
}
