import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./guideToolTip.scss";

export interface IToolTip {
    readonly contentHeading?: string;
    readonly tooltipNo?: string;
    readonly content?: string;
    readonly cancelClick?: () => void;
    readonly buttonName?: string;
    readonly buttonOnClick?: () => void;
    readonly customButtonStyle?: string;
}

function GuideToolTip({
    contentHeading,
    tooltipNo,
    content,
    cancelClick,
    buttonOnClick,
    buttonName,
    customButtonStyle,
}: IToolTip) {
    return (
        <div className="guide-tooltip-arrow">
            <div className="guide-tooltip-container">
                <FontAwesomeIcon className="guide-tooltip-icon" onClick={() => {
                    cancelClick?.();
                }} icon={regular("xmark")} />
                <div className="guide-tooltip-top-layer">
                    <div className="guide-tooltip-heading">{contentHeading}</div>
                </div>
                <div className="guide-tooltip-bottom-layer">
                    <div className="guide-tooltip-number">{tooltipNo}</div>
                    <div className="guide-tooltip-content">{content}</div>
                    {buttonName && <div className="guide-tooltip-button"><button onClick={() => { buttonOnClick?.() }} className={customButtonStyle}> {buttonName}</button></div>}
                </div>
            </div>
        </div>
    );
}

export default GuideToolTip;
