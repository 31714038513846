import { createApi, } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "services/common/rtkQueryConfig";

export const assetVerificationApi = createApi({
  reducerPath: "assetVerificationApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["AssetVerification"],
  endpoints: (build) => ({
    createAssetVerificationOrder: build.mutation<
      AssetVerificationOrder,
      CreateAssetVerificationOrder
    >({
      query: (body: CreateAssetVerificationOrder) => ({
        url: "/AssetVerification/createOrder",
        method: "POST",
        body,
      }),
      invalidatesTags: ["AssetVerification"],
    }),
    getSsoUrl: build.query<SsoUrl, string>({
      query: (orderId) => `/AssetVerification/ssoUrl/${orderId}`,
      providesTags: ["AssetVerification"],
    }),
  }),
});
export const { useCreateAssetVerificationOrderMutation, useGetSsoUrlQuery } =
  assetVerificationApi;

export interface CreateAssetVerificationOrder {
  email: string;
  last4SSN?: string;
  referenceNumber: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  notificationUrl?: string;
  returnUrl?: string;
  employerName?: string;
  ssn?: string;
  dateOfBirth?: string;
  address?: BorrowerAddress;
  consentTS : string;
}

interface AssetVerificationOrder extends CreateAssetVerificationOrder {
  id: string;
  created: Date;
  isArchived: boolean;
  status: string;
  loginUrl: string;
  daysRemaining: number;
  employmentVerificationRequested: boolean;
}

interface SsoUrl {
  url: string;
}
interface BorrowerAddress {
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
}
