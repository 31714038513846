import Overlay from "components/ui/loading-spinners/Overlay/Overlay";
import { useDeleteFailedDocumentsMutation } from "pages/dashboard/home/dashboardService";
import { useGetInterimDashboardQuery } from "pages/application/applicationService";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApplicationStatusNames } from "utilities/Constant";
import { getApplicationRoutingData, getNhDealApplicationId } from "features/authSlice";

const getRouteForApplicationPath = (nhApplicationId?: string): string => {
  return nhApplicationId ? "/application" : "/path";
};

export default function AuthRouting() {
  const navigate = useNavigate();
  const [removeFailedDocuments, { isSuccess: isDeleteFailedDoucumentsSuccess }] = useDeleteFailedDocumentsMutation();
  const authRoutingData = useSelector(getApplicationRoutingData);
  const { data: hasInterimDashboard, isSuccess } = useGetInterimDashboardQuery(useSelector(getNhDealApplicationId));
  const performRouting = () => {
    const { nhApplicationStatus, applicationId } = authRoutingData;
    if ((nhApplicationStatus && nhApplicationStatus.toLowerCase() === ApplicationStatusNames.Completed.toLowerCase()) || hasInterimDashboard) {
      navigate("/dashboard", { replace: true });
      return;
    } else {
      navigate(getRouteForApplicationPath(applicationId), { replace: true });
      return;
    }
  };

  useEffect(() => {
    if (isSuccess && isDeleteFailedDoucumentsSuccess) {
      performRouting();
    }
  }, [isSuccess, isDeleteFailedDoucumentsSuccess]);
  useEffect(() => {
    void (async () => {
      authRoutingData.nhDealApplicationId && (await removeFailedDocuments(authRoutingData.nhDealApplicationId));
    })();
  }, []);

  return <Overlay />;
}
