import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProspectId, setLogout } from "features/authSlice";
import { useNavigate } from "react-router-dom";

import "./header-menu.scss";
import useDesktopChecker from "hooks/useDesktopChecker";
import DropDownMenu from "../DropDownMenu/DropDownMenu";
import ToastMessage from "../toast-message/ToastMessage";
import { useGetPreferencesUrlQuery } from "services/common/complianceService";

export interface HeaderMenuProps {
  name?: string;
  onShow?: boolean;
  showPreferences: boolean;
  showNavbar: () => void;
}

export default function HeaderMenu({ name, onShow, showPreferences, showNavbar }: Readonly<HeaderMenuProps>) {
  const [checked, setChecked] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showErrToast, setShowErrToast] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const complianceId = useSelector(getProspectId);
  const { data, isLoading } = useGetPreferencesUrlQuery(complianceId);
  const isDesktop = useDesktopChecker();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleChange = () => {
    setChecked(!checked);
    setShowPopUp(!showPopUp);
  };

  const onClickLogout = () => {
    dispatch(setLogout());
    navigate("/");
  };

  const onClickPreference = useCallback(() => {
    if (isLoading) return;

    if (data?.preferenceCenterLink) {
      window.open(data.preferenceCenterLink, "_blank");
    } else {
      setShowErrToast(true);
    }
  }, [isLoading, data]);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowPopUp(showPopUp);
        setChecked(checked);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (showErrToast) {
      const timer = setTimeout(() => {
        setShowErrToast(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [showErrToast]);

  return (
    <>
      {showErrToast && !showPopUp && (
        <ToastMessage
          header="Link Unavailable"
          message="Please try again later or contact support"
          state="error"
          timeout={4000}
          position={isDesktop ? "top-center" : "bottom-center"}
        />
      )}
      {isDesktop && (
        <div className="header-menu" onClick={handleChange} ref={dropdownRef}>
          <span className="header-menu__description">{name}</span>
          <span className="header-menu__icon">
            {showPopUp ? <FontAwesomeIcon icon={solid("caret-up")} /> : <FontAwesomeIcon icon={solid("caret-down")} />}
          </span>
        </div>
      )}
      {(onShow || showPopUp) && (
        <DropDownMenu
          onShow={checked || onShow}
          onClickLogout={onClickLogout}
          showNavbar={showNavbar}
          showPreferences={showPreferences}
          onClickPreference={onClickPreference}
        />
      )}
    </>
  );
}
