import { Button } from "components/ui/button/Button";
import "./terms-and-condition/terms-and-conditions.scss";
import Checkbox from "components/ui/checkbox/Checkbox";
import { useSelector } from "react-redux";
import { getStepData } from "components/question/questionSlice";
import { getCurrentUserApplicationId } from "features/authSlice";
import { useSetApplicationStepAnswerMutation } from "pages/application/applicationService";
import { LoadingOverlay } from "components/ui/loading-spinners";
import { useState } from "react";
import {
  useCreateAssetVerificationOrderMutation,
  CreateAssetVerificationOrder,
} from "pages/application/assetVerificationService";
import { SsoIframe } from "./SsoIframe";


export default function AssetVerificationTOS() {
  const stepData = useSelector(getStepData);
  const { nextStepActions: stepActions } = stepData;
  const [isChecked, setIsChecked] = useState(false);

  const [updateApplication] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const applicationId = useSelector(getCurrentUserApplicationId);

  const userApplicationData: CreateAssetVerificationOrder = {
    email: "testNHVOAUser@example.com",
    referenceNumber: applicationId,
    consentTS:  new Date().toISOString()
  };
  const handleSubmit = async (stepActionId: string) => {
    await updateApplication({
      id: applicationId,
      stepActionId,
    });
  };

  const handleCloseIframe = () => 
  {
    toggleModal();
    void handleSubmit(stepActions[0].stepActionId);
  }

    const [CreateAssetVerificationOrder, { isLoading, isSuccess, isError, data }] =
      useCreateAssetVerificationOrderMutation();
    let orderId = "";
    const [show, setShow] = useState<boolean>(false);
    const toggleModal = () => setShow(!show);
    if (isSuccess && data) orderId = data.id;
    const onCreateOrder = async () => {
      await CreateAssetVerificationOrder(userApplicationData);
      toggleModal();
    };
    if (isError) return null;
    if (isLoading) return <LoadingOverlay />;
    if (isSuccess && orderId && show)
      return (
        <SsoIframe
          orderId={orderId}
          showModal={show}
          toggleModal={toggleModal}
          onClose={handleCloseIframe}
        />
      );

  return (
      <div className="toc-container">
        <div className="toc-container__body">
          <span className="toc-container__body--box">
            <Checkbox
              onChange={(checked: boolean) => setIsChecked(checked)}
              checkboxState={isChecked}
            />
            Check here to agree to terms below.
          </span>
          <p className="toc-container__body--text1">
            I agree to the{" "}
            <a className="toc-container__body--text2">Terms of Service</a> and
            request that AccountChek® act as an intermediary on my behalf to
            furnish information to prospective creditor in order to further my
            application.
          </p>
        </div>
        <div>
          <div className="toc-container__button">
            <Button
              className="toc-container__button--btn1"
              title={stepActions[0].label}
              type="round"
              variant="primary"
              onClick={() => void onCreateOrder()}
              disabled={!isChecked}
            />
            <Button
              className="toc-container__button--btn2"
              title={stepActions[1].label}
              type="round"
              variant="outline-primary"
              onClick={() => handleSubmit(stepActions[1].stepActionId)}
            />
          </div>
        </div>
      </div>
  );
}
