import {  RetrieveDocumentsResponseDto, RetrievePalResponseDto } from "pages/dashboard/home/dashboardService";

/**
 * Sorts document response array for documents section "Provided by NAF"
 * @param documents Array of documents to sort
 * @returns Sorted copy of input array
 */
export const sortDocumentsByNaf = (
  documents: RetrievePalResponseDto[]
): RetrievePalResponseDto[] => {
  return [...documents].sort((a, b) => {
    if (a.documentContent?.name === b.documentContent?.name) return 0;

    if (a.documentContent?.name === "Pre-approval Letter") return 1;

    if (b.documentContent?.name === "Pre-approval Letter") return -1;
    
    if(a.documentContent?.name && b.documentContent?.name){
      return a.documentContent?.name > b.documentContent?.name ? 1 : -1;
    }
    return 0;
  });
};
