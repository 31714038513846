export const shareFiles = async (shareData: ShareData) => {
  if (navigator.canShare({ files: shareData.files })) {
    try {
      await navigator.share(shareData);
      console.log("Share was successful.");
    } catch (error) {
      console.error("Sharing failed:", error);
    }
  } else {
    console.log(`Your system doesn't support sharing files.`);
  }
};
