import './raNHRedirect.scss';
import houseImage from 'assets/images/houseImage.svg';
import Header from './header/Header';
import { Button } from 'components/ui/button/Button';
import info from 'assets/images/info_icon.svg';
import { useSearchParams } from 'react-router-dom';
import { RAPID_APP_REDIRECTION_LINK, RAPID_APP_SOURCE_CODE } from 'utilities/Constant';

const RaNHRedirect = () => {
    const [searchParams] = useSearchParams();
    const log = searchParams.get("log");

    const handleRedirection = () => {
        const logParams = log ? `&log=${log}` : "";
        window.location.href = `${RAPID_APP_REDIRECTION_LINK}?src=${RAPID_APP_SOURCE_CODE}${logParams}`;
    }

    return (
        <div className='redirection-message-container'>
            <div className='content'>
                <Header />
                <div className='message-wrapper'>
                    <div className='message'>
                        <div className='heading'>
                            <h1>Better Path to Homeownership</h1>
                        </div>
                        <div className='sub-heading'>
                            <h2>Seamless, Secure, and Built for You</h2>
                        </div>
                        <ul>
                            <li>Streamlined application process for an easy mortgage journey</li>
                            <li>Manage your loan, documents, and communication in one place</li>
                            <li>Secure platform with mobile-friendly access anytime, anywhere</li>
                        </ul>
                        <Button title="Get Started" onClick={handleRedirection} />
                        <div className="info-container">
                            <img src={info} alt='info' />
                            <p>Your previous application is unavailable. Restart it in NAF Hub by
                                re-entering your information.</p>
                        </div>
                    </div>
                    <div className='image-container'>
                        <img src={houseImage} alt='house-image' />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default RaNHRedirect;