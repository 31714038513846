import { PrivacyPolicy } from "components/privacy-policy/PrivacyPolicy";
import * as Constants from "utilities/Constant";
import "./consent.scss";
import { useGetFeatureFlagsQuery } from "services/common/featureFlagService";

export interface ConsentProps {
  partnersLink?: string;
  termsOfUseLink: string;
  privacyLink: string;
  agreementLink: string;
}


export const Consent = ({
  privacyLink,
  partnersLink,
  termsOfUseLink,
  agreementLink,
}: ConsentProps) => {
  const { data: featureFlag } = useGetFeatureFlagsQuery();
  return !featureFlag?.complianceServiceEnabled ? (
    <DefaultConsent
      privacyLink={privacyLink}
      partnersLink={partnersLink}
      termsOfUseLink={termsOfUseLink}
      agreementLink={agreementLink}
    />
  ) : (
    <ComplianceConsent
      privacyLink={privacyLink}
      termsOfUseLink={termsOfUseLink}
      agreementLink={agreementLink}
    />
  );
};

const DefaultConsent = ({
  privacyLink,
  partnersLink,
  termsOfUseLink,
  agreementLink,
}: ConsentProps) => {
  return (
    <div className="consent">
      <div className="consent__wrapper">
        <p className="consent--item">
          {Constants.Legal_Information_One}
          <a href={privacyLink} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          {Constants.Legal_Information_Two}
          <a href={partnersLink} target="_blank" rel="noreferrer">
            partners
          </a>
          {Constants.Legal_Information_Three}
          <a href={partnersLink} target="_blank" rel="noreferrer">
            partners
          </a>
          {Constants.Legal_Information_Four}
          <a href={termsOfUseLink} target="_blank" rel="noreferrer">
            Terms of Use,
          </a>{" "}
          and{" "}
          <a href={agreementLink} target="_blank" rel="noreferrer">
            Electronic Econsent Agreement
          </a>
        </p>
      </div>
      <div className="consent__reader">
        <div className="consent__reader--wrapper">
          {Constants.Assistant_Note}
        </div>
        <PrivacyPolicy privacyLink={privacyLink} />
      </div>
    </div>
  );
};

export const ComplianceConsent = ({
  privacyLink,
  termsOfUseLink,
  agreementLink
}: ConsentProps) => {
  return (
    <div className="consent">
      <div className="consent__wrapper">
        <p className="consent--item">
          {Constants.Legal_Compliance_One}
          <a href={privacyLink} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          {Constants.Legal_Compliance_Two}
          {Constants.Legal_Compliance_Three}
          {Constants.Legal_Compliance_Four}
          <a href={termsOfUseLink} target="_blank" rel="noreferrer">
            Terms of Use
          </a>{" "}
          and{" "}
          <a href={agreementLink} target="_blank" rel="noreferrer">
            Electronic Econsent Agreement
          </a>
          {". "}
        </p>
      </div>
      <div className="consent__reader">
        <div className="consent__reader--wrapper">
          {Constants.Assistant_Note}
        </div>
        <PrivacyPolicy privacyLink={privacyLink} />
      </div>
    </div>
  );
};
