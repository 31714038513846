import WelcomePopup from "pages/dashboard-popup/welcome-popUp/WelcomePopUp";
import "./dashboard-popup.scss";
import PalPopup from "./pal-popup/PalPopup";

interface DashboardPopUpProps {
  readonly onClose: () => void;
  readonly userName?: string;
  readonly showNewPalPopup: boolean;
  readonly handleClick: () => void;
}
export default function DashboardPopUp({ showNewPalPopup, onClose, userName, handleClick }: DashboardPopUpProps) {
  return (
    <div className="pop-up">
      <div className="pop-up__content">
        <div className="pop-up__content1">
          {showNewPalPopup ? <PalPopup onClose={onClose} handleClick={handleClick} /> : <WelcomePopup onClose={onClose} userName={userName} />}
        </div>
      </div>
    </div>
  );
}
