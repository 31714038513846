import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./toast-message.scss";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface ToastMessageProps {
  header?: string;
  message: string;
  timeout?: number;
  state: "success" | "error" | "info" | "warning";
  position?:
    | "top-left"
    | "top-right"
    | "top-center"
    | "bottom-left"
    | "bottom-right"
    | "bottom-center";
}

const ToastMessage = ({
  header,
  message,
  timeout = 1000,
  state,
  position = "top-left",
}: ToastMessageProps) => {
  const ToastContent = (
    <div className="toast-content">
      {header && <p className="toast-header">{header}</p>}
      <p className="toast-content">{message}</p>
    </div>
  );
  const errIcon = <FontAwesomeIcon icon={solid("exclamation-triangle")} />;
  const showToast = () => {
    toast(ToastContent, {
      theme: "colored",
      type: state,
      autoClose: timeout,
      closeButton: false,
      hideProgressBar: true,
      transition: Zoom,
      position: position,
      toastId: Date.now(),
      icon: state === "error" ? errIcon : undefined,
    });
  };
  showToast();
  return <ToastContainer />;
};

export default ToastMessage;
